import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { convertPhoneNumber } from 'modules/authorizations/authorizationEditCreate/utils';

import { detectIE } from '../../../utilities/browserUtils';
import {
  getBusCodes,
  getClientSpecificFields,
  getExceptionsByAuthorizationId,
  getReports,
  getSearchCommentResults,
  getSubmittedAuthorization,
  getTimelineItems,
  setActiveTransfereeTab,
} from '../transferees.actions';
import { getLocaleDateString } from '../../../utilities/dateFormatter';
import { setBackButtonPath, setPageTitle } from '../../layout/layout.actions';
import { trackRecentItem } from '../../../utilities/trackRecents';
import DocumentsNotes from './documentsNotes.component';
import ExpensesView from './expenses.container';
import LogoSpinner from '../../../common/logoSpinner.component';
import ReadOnlyText from '../../../common/readOnlyText.component';
import ServicesCards from './servicesCards.component';
import TimelineList from './timelineList.component';

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 24 }}>
      {props.children}
    </Typography>
  );
};

TabContainer.propTypes = {

};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '2rem',
  },
  errorText: {
    color: 'red',
    fontSize: '1.25rem',
    display: 'inline',
    marginLeft: '0.25rem',
    verticalAlign: 'middle',
  },
  errorIcon: {
    color: 'red',
    fontSize: '1.25rem',
    verticalAlign: 'middle',
  },
  headerBar: {
    width: '100%',
    height: '100%',
    backgroundColor: '#43AAA2',
    lineHeight: '4rem',
    textAlign: 'center',
  },
  headerBarContent: {
    float: 'right',
    display: 'inline-block',
    lineHeight: '4rem',
    marginTop: '1rem',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  coverImageContainer: {
    height: '4rem',
    position: 'relative',
  },
  avatar: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    width: 100,
    height: 100,
    // border: '0.4rem solid white'
    margin: 5,
  },
  transfereeName: {
    position: 'absolute',
    left: '130px',
  },
  headerBarText: {
    // position: 'absolute'
  },
  transfereeNameMobile: {
    margin: '1rem',
  },
  employeeInfoBar: {
    margin: '60px 10px 10px 10px',
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: '10px'
    // }
  },
});

class SubmittedAuthDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingAuth: false,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  async componentDidMount() {
    this.props.setPageTitle('My Transferees');
    this.props.setBackButtonPath('/transferees');
    const selectedAuthId = decodeURIComponent(this.props.match.params.id);

    const {
      submittedAuthDetail,
      userInformation,
    } = this.props;

    await this.props.getSubmittedAuthorization(selectedAuthId);
    await this.props.getExceptionsByAuthorizationId(selectedAuthId);
    await this.props.getSearchCommentResults(selectedAuthId);
    await this.props.getClientSpecificFields({ AuthorizationID: selectedAuthId });
    await this.props.getBusCodes();
    await this.props.getTimelineItems(selectedAuthId);
    await this.props.getReports(selectedAuthId);

    if (submittedAuthDetail && !this.state.isLoadingAuth) {
      const name = `${submittedAuthDetail.FirstName  } ${  submittedAuthDetail.LastName}`;
      const UserName = userInformation.UserName;
      trackRecentItem(this.props.location.pathname, name, 'submittedAuthDetail', UserName);
    }
  }

  handleTabChange = (event, value) => {
    this.props.setActiveTransfereeTab(value);
  };

  render() {
    const { activeTransfereeTab, classes, submittedAuthDetail, submittedAuthExceptions, clientFields, searchComments, isLoadingAuth, busCodes, timelineItems, reports } = this.props;
    const isIE = detectIE();

    if (isLoadingAuth) {
      return (
        <div style={{ margin: '2rem' }}>
          <LogoSpinner />
        </div>
      );
    }

    if (!isLoadingAuth && !submittedAuthDetail) {
      return (
        <div className={classes.root}>
          <Paper>
            <div style={{ padding: '1rem' }}>
              <h3>Transferee Details Error</h3>
              <div>
                <CloseIcon className={classes.errorIcon} />
                <div className={classes.errorText}>There was a problem retrieving transferee details. Please check your connection and try again.</div>
              </div>
            </div>
          </Paper>
        </div>
      );
    }

    const {
      FirstName,
      LastName,
      NickName,
      SpouseFirst,
      SpouseLast,
      MoveTypeDesc,
      MoveReasonDesc,
      OldCity,
      OldState,
      OldCountry,
      NewCity,
      NewState,
      NewCountry,
      NewJobStartDate,
      ProposedEndDate,
      InitiationDate,
      OldMobile,
      Documents,
      ExpenseDetails,
      VIP,
      ClientContact,
      HomesaleRepLast,
      HomesaleRepFirst,
      ReloPolicy,
      FileStatus,
      Division,
      BusinessUnit,
      MoveCap,
    } = submittedAuthDetail;

    // calculate total days and days left for progress indicator
    let totalDays, daysLeft;
    if (submittedAuthDetail && submittedAuthDetail.EstMoveDate && submittedAuthDetail.InitiationDate) {
      const initiationDate = new moment(submittedAuthDetail.InitiationDate).utc().startOf('day');
      const estMoveDate = new moment(submittedAuthDetail.EstMoveDate).utc().startOf('day');

      totalDays = estMoveDate.diff(initiationDate, 'days');

      const today = new moment().utc().startOf('day');

      daysLeft = estMoveDate.diff(today, 'days');
      if (daysLeft <= 0) daysLeft = 0;
    }

    const vendorExpenses = [];

    ExpenseDetails.map((expense) => {
      vendorExpenses.push(Object.assign({}, expense, { Type: 'Vendor' }));
      return '';
    });

    return (
      <div>
        <div className={classes.coverImageContainer}>
          <div className={classes.headerBar}>
            <span className={classes.headerBarContent}>
              <div className={classes.transfereeName}>
                <Typography style={{ fontSize: '1.5rem', color: 'white' }}>
                  {`${FirstName  } ${  LastName  }${NickName ? ` (${NickName})` : ''}`}
                </Typography>
              </div>
              <span>
                {VIP && <div style={{ float: 'left', marginRight: '1rem' }}><Typography style={{ color: 'white', fontSize: '1.5rem' }}>VIP<FontAwesomeIcon icon="check" style={{ marginLeft: '0.25rem' }} /></Typography></div>}
                {/* <div style={{ float: 'right', marginRight: '1rem' }}><Typography style={{ color: 'white', fontSize: '1.5rem' }}><HeartIcon style={{ marginLeft: '0.25rem' }} /></Typography></div> */}
              </span>
            </span>
          </div>
          <Avatar className={classes.avatar}><AccountCircle style={{ height: '120px', width: '120px', marginLeft: isIE ? '-10px' : '0' }} /></Avatar>
        </div >
        <div className={classes.employeeInfoBar} >
          <Grid container>
            <Grid item xs={12} sm={6} lg={3}>
              <ReadOnlyText
                label="Spouse / Partner Name"
                name="spouse"
                value={(SpouseFirst && SpouseLast) ? `${SpouseFirst  } ${  SpouseLast}` : ' '}
              />
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyText
                    label="Employee Type"
                    name="employeeType"
                    value={(MoveTypeDesc) ? MoveTypeDesc : ' '}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyText
                    label="Move Reason"
                    name="moveReason"
                    value={(MoveReasonDesc) ? MoveReasonDesc : ' '}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ReadOnlyText
                label="Origin Location"
                name="origin"
                value={(OldCity && OldState) ? `${OldCity  }, ${  OldState  }${OldCountry ? ` ${  OldCountry}` : ' '}` : ' '}
              />
              <ReadOnlyText
                label="Destination Location"
                name="destination"
                value={(NewCity && NewState) ? `${NewCity  }, ${  NewState  }${NewCountry ? ` ${  NewCountry}` : ' '}` : ' '}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyText
                    label="Effective Date"
                    name="startDate"
                    value={(NewJobStartDate) ? getLocaleDateString(NewJobStartDate) : ' '}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyText
                    label="Proposed End"
                    name="endDate"
                    value={(ProposedEndDate) ? getLocaleDateString(ProposedEndDate) : ' '}
                  />
                </Grid>
              </Grid>
              <ReadOnlyText
                label="Authorized By"
                name="authBy"
                value={(ClientContact) ? ClientContact : ' '}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyText
                    label="Contact Number"
                    name="oldMobile"
                    value={convertPhoneNumber(OldMobile)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyText
                    label="Auth Date"
                    name="authDate"
                    value={(InitiationDate) ? getLocaleDateString(InitiationDate) : ' '}
                  />
                </Grid>
              </Grid>
              <ReadOnlyText
                label="Relocation Counselor"
                name="reloCounselor"
                value={(HomesaleRepFirst && HomesaleRepLast) ? `${HomesaleRepFirst} ${HomesaleRepLast}` : ' '}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <ReadOnlyText
                label="Policy"
                name="policy"
                value={(ReloPolicy) ? ReloPolicy : ' '}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ReadOnlyText
                label="Status"
                name="status"
                value={(FileStatus) ? FileStatus : ' '}
              />
            </Grid>
            {
              Division &&
                <Grid item xs={12} sm={6} md={3}>
                  <ReadOnlyText
                    label="Division"
                    name="division"
                    value={(Division) ? Division : ' '}
                  />
                </Grid>
            }
            {
              BusinessUnit &&
                <Grid item xs={12} sm={6} md={3}>
                  <ReadOnlyText
                    label="Business Unit"
                    name="policy"
                    value={(BusinessUnit) ? BusinessUnit : ' '}
                  />
                </Grid>
            }
          </Grid>
        </div >
        <AppBar position="static">
          <Tabs
            value={activeTransfereeTab}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Employee Services" />
            <Tab label="Expenses" />
            <Tab label="Documents / Notes" />
            <Tab label="Timeline" />
          </Tabs>
        </AppBar>

        {activeTransfereeTab === 0 && <TabContainer><ServicesCards submittedAuthDetail={submittedAuthDetail} clientFields={clientFields} /></TabContainer>}
        {activeTransfereeTab === 1 && <TabContainer><ExpensesView exceptions={submittedAuthExceptions} reports={reports} vendorExpenses={vendorExpenses} moveCap={MoveCap} /></TabContainer>}
        {activeTransfereeTab === 2 && <TabContainer><DocumentsNotes documents={Documents} comments={searchComments} busCodes={busCodes} /></TabContainer>}
        {activeTransfereeTab === 3 &&
          <TabContainer>
            <TimelineList
              data={timelineItems}
              daysLeft={daysLeft}
              totalDays={totalDays}
              showVideoModal={() => console.log('showVideoModal')}
              showInfoModal={() => console.log('showInfoModal')}
              handleMarkComplete={() => console.log('handleMarkComplete')}
            />
          </TabContainer>
        }
      </div >
    );
  }
}

SubmittedAuthDetails.propTypes = {

};

const mapStateToProps = (state) => {
  return {
    submittedAuthDetail: state.transferees.get('submittedAuthDetail'),
    submittedAuthExceptions: state.transferees.get('submittedAuthExceptions'),
    clientFields: state.transferees.get('clientFields'),
    searchComments: state.transferees.get('searchComments'),
    userInformation: state.layout.get('userInformation'),
    isLoadingAuth: state.transferees.get('isLoading'),
    busCodes: state.transferees.get('busCodes'),
    timelineItems: state.transferees.get('timelineItems'),
    reports: state.transferees.get('reports'),
    activeTransfereeTab: state.transferees.get('activeTransfereeTab'),
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    getSubmittedAuthorization,
    getExceptionsByAuthorizationId,
    getClientSpecificFields,
    setPageTitle,
    getSearchCommentResults,
    getBusCodes,
    getTimelineItems,
    getReports,
    setBackButtonPath,
    setActiveTransfereeTab,
  }),
)(SubmittedAuthDetails);
